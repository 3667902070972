















import { Component } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import ExtraProperty from '@/models/graphql/ExtraProperty';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import WidgetHelper from '@/utils/helpers/widgets/WidgetHelper';
import { Location } from 'vue-router';
import ProductCertificatePartial from '@/utils/partials/ProductCertificatePartial';

@Component
export default class CertificateBadgeComponent extends mixins(VueBaseWidget, ProductCertificatePartial, VueRegisterStoreWidget) {
  private hideOnError = false;

  get extraProps(): ExtraProperty[] {
    return this.all as unknown as ExtraProperty[];
  }

  created(): void {
    if (this.payload
      && 'entityCode' in this.payload
      && this.payload.entityCode) {
      const entityCode = WidgetHelper
        .entityCode(this.payload.entityCode as string, this.$route as unknown as Location);
      this.setDataConfig([{
        alias: this.storeName,
        operation: 'extraProperty',
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            type: GqlEntityFilterType.EXTRA_PROPERTY_FILTER,
            value: {
              OR: [
                {
                  key: 'NMMA_CERTIFIED',
                  largeProduct: {
                    uid: entityCode,
                  },
                },
                {
                  key: 'CERTIFICATE',
                  largeProduct: {
                    uid: entityCode,
                  },
                },
              ],
            },
          },
        }),
        fragmentName: 'extraPropertyBaseFragment',
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
        `Missing entityCode in payload for ${this.widget.type} widget with id ${this.widget.id}`,
      );
    }
  }
}
